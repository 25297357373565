import { createClient } from "@supabase/auth-helpers-sveltekit";
import {
  PUBLIC_SUPABASE_URL,
  PUBLIC_SUPABASE_ANON_KEY,
} from "$env/static/public";

export const supabase = createClient(
  PUBLIC_SUPABASE_URL,
  PUBLIC_SUPABASE_ANON_KEY
);

export async function insertIntoTable(tableName, data) {
  const { error } = await supabase
    .from(tableName)
    .upsert(data, { onConflict: ['lottery_name', 'draw_number'] });


  if (error) {
    console.error(`Error inserting data into table ${tableName}:`, error);
    throw new Error("Database insertion failed");
  }
}

export default supabase;
